import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useFormState } from 'react-final-form';

import CustomSelect from 'components/CustomSelect';
import {
  ModalTableAddSubdivision,
  TableDefaultRow,
  TableDraftRowPayload,
} from '../components';
import { UserChoice } from '../views/route-create';

type SubdivisionsInputProps = {
  draftRows: TableDraftRowPayload[] | null;
  supervisor: UserChoice | null;
  type?: 'edit' | 'create';
  updateDraftRows: (rows: TableDraftRowPayload[] | null) => void;
};

export const SubdivisionsInput = ({
  draftRows,
  supervisor,
  type = 'create',
  updateDraftRows,
}: SubdivisionsInputProps) => {
  const record = useRecordContext();
  const { values, dirtyFields } = useFormState();
  const classes = useStyles();

  const [showEditBuildersNameModal, setShowEditBuildersNameModal] =
    useState(false);

  const subdivisions = record?.subdivisions ? [...record.subdivisions] : [];

  // Only show current subdivisions if the form is not dirty
  const currentSubdivisions =
    record?.subdivisions && !dirtyFields?.regionId ? subdivisions : [];
  const mergedRows = [
    ...currentSubdivisions,
    ...(draftRows?.map(draftRow => ({
      id: draftRow.subdivisionId,
    })) ?? []),
  ];
  const selectedSubdivisions = mergedRows?.map(draftRow => draftRow.id) ?? [];

  const createTableData = ({
    id,
    subdivisionId,
    name,
  }: TableDefaultRow): TableDefaultRow => {
    return { id, subdivisionId, name };
  };

  const rows: TableDefaultRow[] | null =
    currentSubdivisions?.map(subdivision =>
      createTableData({
        id: subdivision.id,
        subdivisionId: subdivision.id,
        name: subdivision.name,
      }),
    ) || null;

  return (
    <>
      <Box className={classes.fullBox}>
        <CustomSelect
          resource="subdivisions"
          label="Subdivisions"
          margin="dense"
          variant="filled"
          multiple
          renderValue={(value: unknown) => (
            <div className={classes.chips}>
              {(value as string[])
                .map(item => subdivisions?.find(choice => choice.id === item))
                .filter(item => !!item)
                .map(item => (
                  <Chip key={item?.id} label={item?.name} />
                ))}
            </div>
          )}
          disabled
          options={subdivisions}
          value={selectedSubdivisions}
        />
        <Box className={classes.addIcon}>
          <IconButton
            disabled={!values.supervisorUserId}
            onClick={() => setShowEditBuildersNameModal(true)}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      {showEditBuildersNameModal && (
        <ModalTableAddSubdivision
          type={type}
          open={showEditBuildersNameModal}
          onClose={() => setShowEditBuildersNameModal(false)}
          columns={[
            'Subdivision Name',
            'Current Route',
            'Current Supervisor',
            'New Route',
            'New Supervisor',
          ]}
          draftRows={draftRows}
          updateDraftRows={updateDraftRows}
          supervisor={supervisor}
          rows={rows}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  fullBox: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
    width: '100%',
  },
  addIcon: {
    marginTop: -20,
  },
  chips: {
    gap: '4px',
    display: 'flex',
    flexWrap: 'wrap',
  },
});
