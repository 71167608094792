import { DataProvider } from 'react-admin';
import { RouteDto } from '@vatos-pas/common';

export const findRouteBySupervisor = async (
  dataProvider: DataProvider,
  supervisorUserId: string,
): Promise<RouteDto[]> => {
  try {
    const roles = await dataProvider.getList<RouteDto>('route', {
      filter: {
        supervisorUserId,
        $join: [{ field: 'subdivisions' }],
      },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
    });

    return roles.data;
  } catch (error: any) {
    return error;
  }
};

export const findRouteByRegion = async (
  dataProvider: DataProvider,
  regionId: string,
): Promise<RouteDto[]> => {
  try {
    const roles = await dataProvider.getList<RouteDto>('route', {
      filter: {
        regionId,
      },
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'id', order: 'DESC' },
    });

    return roles.data;
  } catch (error: any) {
    return error;
  }
};

export const findRouteBySubdivision = async (
  dataProvider: DataProvider,
  subdivisionId: string,
): Promise<RouteDto[]> => {
  try {
    const route = await dataProvider.getList<RouteDto>('route', {
      filter: {
        $join: [{ field: 'subdivisions||id' }],
        'subdivisions.id': subdivisionId,
      },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
    });

    return route.data;
  } catch (error: any) {
    return error;
  }
};
